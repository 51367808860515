@font-face {
    font-family: gib-reg;
    src: url(../fonts/Gibson-Regular.otf);
    font-weight: 400;
    font-display: fallback;
}
@font-face {
    font-family: gib-semi;
    src: url(../fonts/Gibson-SemiBold.otf);
    font-weight: 700;
    font-display: fallback;
}
@font-face {
    font-family: gib-bold;
    src: url(../fonts/Gibson-Bold.otf);
    font-weight: 800;
    font-display: fallback;
}



.wrapper {
    max-width: 767px;
    margin: 0 auto;
    overflow: hidden;
}
* {
    box-sizing: border-box;
}
body{
    margin: 0;
}


section.banner {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    padding: 25px 0 40px 0;
}
section.banner:before {
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    /* background-image: linear-gradient(transparent -60%, #fff 120%); */
    background-image: linear-gradient(to bottom left, transparent -30%, #000 140%);
    /* background-color: rgba(0, 0, 0, 0.18); */
    position: absolute;
    z-index: 0;
}
.logo-section {
    position: relative;
    text-align: center;
}
.logo-section a img {
    width: 140px;
    object-fit: contain;
}
.banner-text {
    position: relative;
    padding: 0 50px;
    color: #fff;
}
.banner-text h2 {
    font-family: gib-semi;
    font-size: 36px;
    padding: 0 20px;
    line-height: 12px;
    text-align: center;
}
.banner-text h2 span {
    color: #FFC814;
    display: block;
    font-size: 52px;
    padding-left: 82px;
    line-height: 1;
}
.banner-text h2 span:before {
    content: "";
    position: absolute;
    left: 0;
    top: 42px;
    width: 33%;
    height: 4px;
    background-color: #FFC814;
    display: none;
}
.banner-text h2 span strong {
    color: #fff;
    margin-left: 1px;
}
.banner-text p:after {
    content: "";
    position: absolute;
    left: auto;
    top: -52px;
    /* width: 59px;*/
    height: 4px;
    background-color: #FFC814;
    right: 80%;
    width: 200%;
}
.banner-text p {
    font-family: 'gib-reg';
    line-height: 1.5;
    text-align: center;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}



/*************** Slection Location ***************/

.location-content ul {
    /*width: 80%;*/
    margin-left: auto;
    margin-right: auto;
    list-style: none;
    padding: 8px 50px 0px 50px;
}
.location-content ul li {
    display: flex;
    margin-bottom: 30px;
    align-items: center;
    border-bottom: 1px solid #000;
    padding-bottom: 30px;
}
.location-content ul li:last-child {
    margin-bottom: 0;
    padding-bottom: 6px;
}
.location-content ul li:last-child{
    border-bottom: none;
}
span.text-dp {
    background-color: #460ce8;
    color: rgb(255, 255, 255);
    font-family: 'gib-semi';
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 60px;
    height: 60px;
    text-transform: uppercase;
    padding: 0px 10px;
}
.r-name {
    margin-left: 22px;
    flex: 1 1 0%;
}
.r-name h2 {
    font-family: 'gib-semi';
    margin: 0px;
}
.location-content ul li a {
    background-color: #ffc814;
    color: #000;
    display: block;
    font-size: 16px;
    font-family: 'gib-semi';
    font-weight: 600;
    text-decoration: none;
    padding: 14px 30px;
    border-radius: 30px;
    margin-left: 15px;
}
.location-content ul li button {
    background-color: #ffc814;
    color: #000;
    border: #ffc814;
    display: block;
    font-size: 16px;
    font-family: 'gib-semi';
    font-weight: 600;
    text-decoration: none;
    padding: 14px 30px;
    border-radius: 30px;
    margin-left: 15px;
}
section.select-location {
    padding: 30px 0 10px 0;
}
.head h1 {
    text-align: center;
    font-size: 32px;
    font-family: 'gib-semi';
    margin-bottom: 40px;
    line-height: 38px;
}
section.video-section video, section.video-section iframe {
    width: 100%;
    /*   height: 480px;*/
    object-fit: cover;
    border-radius: 8px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    z-index: 9;
    box-shadow: 0 0px 13px rgb(0 0 0 / 50%);
}

section.video-section video:focus, section.video-section iframe:focus{
    outline: none;
}
section.video-section {
    padding: 30px;
    position: relative;
}
.video-section:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 70%;
    background-color: #460ce8;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    margin: auto;
}
.play-icon {
    position: absolute;
    width: 100px;
    height: 100px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: none;
}
.video-wrapper:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100% - 3px);
    background-color: rgba(0,0,0,0.3);
    display: none;
    border-radius: 8px;
}
.play-icon img {
    width: 100%;
    height: 100%;
}
.video-wrapper {
    position: relative;
    padding-bottom: 56.2%;
    /* padding-top: 25px;*/
    height: 0;
}
.play-btn.video-wrapper:before{
    display: block;
}
.play-btn .play-icon {
    display: block;
}
/********** FAQ **************/
.faq-area {
    padding: 2px 30px;
}
.panel {
    margin: 0;
}
.panel h4 {
    background-color: rgb(255, 255, 255);
    font-size: 24px;
    font-family: 'gib-semi';
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1.1;
    border-radius: 10px;
    overflow: hidden;
    margin: 0px;
    padding: 30px 0 25px 0;
}
.panel .panel-content {
    background-color: rgb(255, 255, 255);
    width: 100%;
    margin-top: 0;
    border-radius: 0px 0px 10px 10px;
    padding: 0;
    transition: max-height 400ms;
    overflow: hidden;
    height: auto;
}
.panelOpen {
    max-height: 600px;
}
.panelClosed {
    max-height: 0px;
}
.panel .panel-content h5 {
    color: rgb(128, 128, 128);
    font-size: 14px;
    font-family: 'gib-reg';
    padding: 0 35px 0 0;
    margin: 0 0 25px 0;
    line-height: 1.5;
    font-weight: 400;
}
.faq-area .divider {
    width: 110%;
    height: 1px;
    background-color: #c5c5c5;
    margin: 0 0 0 -5%;
}
.panel h4 span {
    line-height: 14px;
    margin-left: 30px;
}
.panel h4 span img {
    width: 24px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    transition: 400ms all;
}
.panel h4.for-img span img{
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}
section.faq {
    padding: 10px 0 30px 0;
}

/**************** Footer *********/
.copy-rights {
    text-align: center;
}
.copy-rights a.f-logo img {
    width: 300px;
}
.copy-rights p {
    color: rgb(255, 255, 255);
    font-family: 'gib-reg';
    font-size: 12px;
    font-weight: 100;
    margin: 10px 0px 0px;
}
.copy-rights ul {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    padding: 0px;
    list-style: none;
}
.copy-rights ul li.divider {
    width: 1px;
    height: 14px;
    background-color: rgb(255, 255, 255);
    position: relative;
    top: 3px;
    margin: 0px 10px;
}
.copy-rights ul li a {
    color: rgb(255, 255, 255);
    font-family: 'gib-reg';
    font-size: 12px;
    font-weight: 100;
    text-decoration: none;
}
section.footer-section {
    background-color: #000;
    padding: 60px 30px;
}

/********************* Next Page ***********/


.search-zone {
    padding: 0 30px;
    border-top: 3px solid #000;
}
form.search-field {
    margin-top: -33px;
    position: relative;
}
form.search-field input{
    width: 100%;
    border: 3px solid #000;
    height: 65px;
    border-radius: 6px;
    font-size: 18px;
    padding: 0px 55px 0px 16px;
    box-shadow: 0 9px 13px rgb(0 0 0 / 20%);
    font-family: 'gib-reg';
    transition: 0.3s all;
    font-weight: 600;
}
form.search-field input::placeholder{
    opacity: 1;
    color: #000;
}
form.search-field input:focus, form.search-field button:focus{
    outline: none;
}
form.search-field button {
    position: absolute;
    border: none;
    background: none;
    top: 7.5px;
		right: 7.5px;
    padding: 0;
		box-sizing: border-box;
		height: 50px;
}


form.search-field input[type="text"].search-error {
    border-color: red;
}
form.search-field span.text-red {
    font-family: "gib-reg";
    margin-top: 10px;
    display: block;
    margin-left: 22px;
    color: red;
}
form.search-field button img {
    width: 50px;
		height: 50px;
}
form.search-field button img:last-child{
    display: none;
}
form.search-field input:focus{
    border-color: #4701bf;
}
form.search-field input:focus + button img:last-child{
    display: block;
}
form.search-field input:focus + button img:first-child{
    display: none;
}


.how-it-work{
    padding: 30px 0 20px 0;
}
.it-works {
    padding: 0 30px;
}
.it-works ul {
    list-style: none;
    padding: 0;
    width: 382px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
}
.it-works ul li {
    display: flex;
    align-items: center;
    padding-bottom: 54px;
    justify-content: center;
    position: relative;
}
.it-works ul li span {
    background-color: #dacefa;
    font-size: 30px;
    font-family: 'gib-bold';
    line-height: 1;
    padding: 22px 20px 20px 26px;
    border-radius: 50%;
}
.it-works ul li p {
    margin: 0;
    font-family: 'gib-reg';
    line-height: 1.3;
    width: 190px;
    font-size: 18px;
}
.it-works ul li:nth-child(odd) {
    background-image: url(../img/line1.png);
    background-repeat: no-repeat;
    background-size: 336px;
    background-position: 15px bottom;
}
.it-works ul li:nth-child(odd) span {
    margin-right: 30px;
}
.it-works ul li:nth-child(odd) p {
    margin-right: 50px;
}
.it-works ul li:nth-child(2) {
    background-image: url(../img/line2.png);
    background-repeat: no-repeat;
    background-size: 336px;
    background-position: 15px bottom;
}
.it-works ul li:nth-child(even) p {
    margin-left: 40px;
    margin-right: 10px;
}
.it-works ul li:nth-child(even) span {
    margin-left: 0;
}
.it-works ul li:last-child{
    padding-bottom: 0;
}
.it-works ul li:last-child p{
    font-family: "gib-semi";
}

/********** start page **************/
.wrapper.start-screen{
    background-image: linear-gradient(to bottom left, #535353, #000);
}
section.start-screen {
    position: relative;
    width: 100%;
    height: 100vh;
}
.loader img:first-child {
    width: 110px;
    margin: 0 auto;
    position: relative;
    display: block;
    top: 60px;
}
.loader img:last-child {
    position: relative;
    top: -96px;
}

.loader img {
    width: 100%;
}
.start-footer {
    position: absolute;
    width: 300px;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 25px;
}
.start-footer img {
    width: 100%;
    margin: 0 auto;
    display: block;
}
.loader {
    position: absolute;
    width: 200px;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    height: 200px;
}

/****************** payment ****************/

section.pay-method {
    margin: 55px 0 45px 0;
    padding: 0 30px;
}
.methods ul {
    list-style: none;
    padding: 0;
}
.methods ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 25px;
    margin-bottom: 25px;
    border-bottom: 1px solid #000;
}
.methods ul li img {
    max-width: 170px;
    max-height: 42px;
    height: auto;
}
.pay-on a {
    display: block;
    text-decoration: none;
    color: #000;
    font-family: 'gib-semi';
    border: 3px solid #000;
    padding: 6px 44px;
    border-radius: 30px;
    font-size: 14px;
    text-align: center;
}

.pay-on a:first-child {
    /* Removed margin-bottom as we only have 1 button now (not `Pay on Web` and `Pay on App` for a given provider) */    
    /* margin-bottom: 20px; */
    border-color: #ffc814;
    background-color: #ffc814;
}
.methods ul li:last-child {
    border-bottom: none;
}

.logo-section.dual-logo {
    display: flex;
    justify-content: space-between;
    padding: 0 30px 0px 30px;
    align-items: center;
}

.body-loader {
    display: none;
}
.body-loader div {
    min-height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;

}
.body-loader div span {
    background-image: url(../img/dark-loader.gif);
    padding: 54px 36px;
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
    font-family: 'gib-semi';
    text-transform: uppercase;
    font-size: 14px;
}


.it-works ul li:after {
    content: "";
    width: 100%;
    position: absolute;
    background-color: white;
    bottom: 0;
    transition: 0.7s all;
    height: 54px;
}
.it-works ul li:last-child:after{
    display: none;
}
.it-works ul li.animate-it-left:after{
    width: 0px;
    left: 0px;
}
.it-works ul li.animate-it-right:after{
    width: 0px;
    right: 0px;
}

.head h1 br {
    display: none;
}




/********** Media ***********/


@media(min-width: 2000px){
    .banner-text h2 span:before{
        width: 36%;
    }
}
@media(min-width: 2500px){
    .banner-text h2 span:before{
        width: 40%;
    }
}
@media(min-width: 991px){
    .copy-rights {
        text-align: left;
        display: flex;
        justify-content: center;
    }
    .copy-rights a.f-logo img {
        width: 350px;
    }
    .copy-rights .footer-menu {
        margin-left: 30px;
        margin-top: 12px;
    }
    .copy-rights ul{
        margin-bottom: 0;
    }
    .faq-area {
        display: flex;
        width: 100%;
        padding: 0 30px;
    }
    .panel {
        width: calc(50% - 43px);
    }
    .faq-area .divider{
        margin: 0 70px;
        min-width: 1px;
        height: auto;
        width: 1px;
    }
    .faq-area .hiddenNonMobile {
        display: none;
    }
    .panel h4 span{
        margin-left: 15px;
    }
    .panel h4{
        font-size: 22px;
    }
    .panel .panel-content h5{
        font-size: 14px;
    }

    .it-works ul li:after{
        display: none;
    }
    .it-works ul {
        display: flex;
        width: 100%;
        margin-bottom: 92px;
    }
    .it-works ul li:nth-child(even) {
        position: relative;
        top: 72px;
    }
    .it-works ul li {
        padding: 0;
        flex-wrap: wrap;
        align-items: flex-start;
    }
    .it-works ul li span {
        display: block;
        width: 73px;
        order: 0;
    }
    .it-works ul li p {
        order: 1;
        text-align: center;
        margin-top: 12px;
    }
    .it-works ul li:nth-child(odd) span, .it-works ul li:nth-child(odd) p {
        margin-right: 0;
    }
    .it-works ul li:nth-child(even) p{
        margin-left: 0;
    }
    .it-works ul li:nth-child(odd), .it-works ul li:nth-child(even){
        background: none !important;
    }
    .it-works ul li:nth-child(odd):before {
        content: "";
        background-image: url(../img/desk-line-1.png);
        background-repeat: no-repeat;
        background-size: 100%;
        position: absolute;
        right: -75px;
        width: 145px;
        height: 48px;
        bottom: 36px;
        z-index: -1;
    }
    .it-works ul li:nth-child(2):before {
        content: "";
        background-image: url(../img/desk-line-2.png);
        background-repeat: no-repeat;
        background-size: 100%;
        position: absolute;
        right: -70px;
        width: 144px;
        height: 48px;
        top: -23px
    }
}

/*@media(max-width: 850px){
    section.video-section video, section.video-section iframe {
        height: 415px;
    }
}
@media(max-width: 767px){
    section.video-section video, section.video-section iframe {
        height: 370px;
    }
}*/

@media(min-width: 776px){
    .banner-text p:after{
        /*left: -100%;
    width: 350px;*/
        left: auto;
        /*width: 262px;*/
        width: 500%;
        right: 71%;
    }
    .banner-text h2 span:before{
        display: none;
    }
    .banner-text{
        overflow: hidden;
    }
    .banner-text p{
        width: 100%;
        max-width: 424px;
    }
    form.search-field{
        width: 100%;
        max-width: 424px;
        margin-left: auto;
        margin-right: auto;
    }
    .container {
        max-width: 991px;
        margin: 0 auto;
    }
    .wrapper{
        max-width: 100%;
    }
    .banner-text h2{
        padding: 0 180px 0 0px;
    }
    .banner-text h2 span{
        padding-left: 262px;
    }
}
/*@media(max-width: 680px){
    section.video-section video, section.video-section iframe{
        height: 320px;
    }
}*/

@media(max-width: 550px){
    /*  section.video-section video, section.video-section iframe{
          height: 255px;
      }*/
    /* .head h1{
         padding: 0 30px;
     }*/
    .banner-text h2 span:before{
        width: 23%;
    }
}

@media(max-width: 480px){
    /*   section.video-section video, section.video-section iframe{
           height: 228px;
       }*/
    .play-icon{
        width: 80px;
        height: 80px;
    }
    .video-section:before{
        height: 60%;
    }
    .banner-text{
        padding: 0 20px;
    }
    .banner-text h2{
        padding: 0;
    }
    .r-name h2, .panel h4{
        font-size: 20px;
    }
    .panel .panel-content h5{
        font-size: 14px;
    }
    .panel .panel-content h5{
        margin: 0 0 18px 0;
    }

    span.text-dp{
        min-width: 50px;
        height: 50px;
    }
    .location-content ul li a{
        padding: 10px 20px;
    }
    .location-content ul{
        padding: 18px 30px 0px 30px;
    }
    section.video-section {
        padding: 30px;
        position: relative;
    }
    .faq-area {
        padding: 2px 30px;
    }
    .head h1{
        margin-bottom: 30px;
    }

    .panel h4{
        padding: 18px 0;
    }
    section.footer-section{
        padding: 60px 0px;
    }
    .logo-section a img {
        width: 100px;
        max-height: 85px;
    }
    .banner-text h2{
        line-height: 12.5px;
    }
    .head h1{
        font-size: 26px;
        line-height: 32px;
    }
}
@media(max-width: 420px){
    /*  section.video-section video, section.video-section iframe{
              height: 194px;
      }*/
    .banner-text h2{
        font-size: 30px;
    }
    .banner-text h2 span{
        font-size: 40px;
    }
    .banner-text h2 span:before {
        width: 30%;
        top: 36px;
    }
    /*.banner-text p:after{
        display: none;
    }*/
    .copy-rights ul{
        flex-wrap: wrap;
    }
    /*.copy-rights ul li.divider{
        display: none;
    }*/
    /* .copy-rights ul li {
         margin: 12px 0;
     }*/
    /*.banner-text p{
            width: 100%;
        }*/
    .banner-text p:after{
        top: -41px;
        left: auto;
        right: 71%;
    }
    .pay-on {
        display: flex;
        justify-content: center;
    }
    .pay-on a{

        padding: 6px 22px;
    }
    .pay-on a:first-child{
        margin-bottom: 0;
    }
    .methods ul li img {
        margin: 0 auto 0 0;
        display: block;
    }
    .head h1 {
        /*padding: 0;*/
    }
    .it-works ul li:nth-child(odd) p {
        margin-right: 0;
    }
    .it-works ul li:nth-child(even) p {
        margin-left: 0;
    }
    .it-works ul li:nth-child(odd), .it-works ul li:nth-child(2){
        background-size: 263px;
        background-position: center 77px;
    }
    .it-works ul{
        width: 100%;
    }
    .it-works ul li{
        padding-bottom: 48px;
    }
    .it-works ul li:after{
        height: 41px;
    }
}
@media(max-width: 340px){
    .banner-text h2 {
        font-size: 30px;
    }
    .banner-text h2 span {
        font-size: 36px;
    }
    .banner-text h2 span:before {
        width: 30%;
        top: 32px;
    }
    .banner-text p:after {
        right: 68%;
    }
    .banner-text p{
        width: 280px;
    }
    .methods ul li{
        display: block;
    }
    .methods ul li img {
        margin: 0 auto 10px auto;
        display: block;
    }
    .logo-section.dual-logo a:last-child img {
        width: 130px;
    }
    .logo-section a img {
        width: 85px;
    }
    .banner-text h2{
        line-height: 12px;
    }
    .copy-rights ul li:last-child {
        width: 100%;
        margin: 5px 0 0 0;
    }
    .copy-rights ul li.divider:nth-last-child(2) {
        display: none;
    }
}
